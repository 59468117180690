import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

/* Adds http client for communication */
import {  HttpClient , HttpErrorResponse, HttpHeaders } from  '@angular/common/http';



/* Observables are new versions of promise */
import { Observable , throwError } from  'rxjs';
import { catchError, map } from  'rxjs/operators';
// import {isNull} from "@angular/compiler/src/output/output_ast";
// import {url} from "inspector";

@Injectable({
  providedIn: 'root'
})
export class RestService {
  baseUrl = 'https://api.cms.myaamiastoryteller.org/api/v2';
  RawAPIKey = '4999e206825e2ad557c86e3a18f1c012217767e41d246feda2fef345aa51683c';
  APIKey = 'api_key=' + this.RawAPIKey;
  query: string;



  constructor(private  httpClient: HttpClient,
              private router: Router,
              ) {
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }


  public getThemes(): Observable<Theme[]> {

    console.log('Call getThemes');
    return this.httpClient
      .get<Theme[]>(this.baseUrl + '/gis/_proc/get_themes()?' + this.APIKey)
      .pipe(
        catchError(this.handleError)
      );

  }

  public getSubjects(inThemeID: number): Observable<Subject[]> {

    console.log('Call getSubjects');
    return this.httpClient
      .get<Subject[]>(this.baseUrl + '/gis/_proc/get_subject_by_theme(' + inThemeID.toString() + ')?' + this.APIKey)
      .pipe(
        catchError(this.handleError)
      );

  }


  public getJourneyList(inSubjectID: number): Observable<Journey[]> {

    console.log('Call getJournies');
    return this.httpClient
      .get<Journey[]>(this.baseUrl + '/gis/_proc/get_journey_by_subject(' + inSubjectID.toString() + ')?' + this.APIKey)
      .pipe(
        catchError(this.handleError)
      );

  }

  public getGeoJSON( id: number): Observable<string> {
    var URL=this.baseUrl + '/gisGeoJson?journey_id=' + id.toString() + '&' + this.APIKey;
    console.log(URL);
    return this.httpClient
      .get<string>(this.baseUrl + '/gisGeoJson?journey_id=' + id.toString() + '&' + this.APIKey)
      .pipe(
        catchError(this.handleError)
      );
    // oReq.open('GET', 'https://api.cms.myaamiastoryteller.org/api/v2/gisGeoJson?journey_id='+id+'&api_key=4999e206825e2ad557c86e3a18f1c012217767e41d246feda2fef345aa51683c');

  }

  public trimString(myString: string, myLength: number) {
    return myString.length > myLength ?
      myString.substring(0, myLength) + '...' :
      myString;
  }

}
  export class Theme {
  theme_id: number;
  description: string;
  image: string;
  title: string;
  image_description: string;
  attribution: string;
  showMore: boolean;


}

export class Subject {
  subject_id: number;
  description: string;
  image: string;
  title: string;
  image_description: string;
  attribution: string;
  theme_id: number;
  showMore: boolean;

}

export class Journey {
  journey_id: number;
  route_id: number;
  start_datetime: string;
  end_datetime: string;
  load_count: number;
  description: string;
  population_type: number;
  image_collection_id: number;
  subject_id: number;
  title: string;
  image_description: string;
  image_address: string;
  showMore: boolean;

}

